$fontSizeFinePrint: 11px;
$fontSizeSmall: 12px;
$fontSizeRegularText: 14px;
$fontSizeRegular: 16px;
$fontSize-4: 18px;
$fontSize-3: 24px;
$fontSize-2: 32px;
$fontSize-1: 40px;

$fontSizeLineHeightSmall: 16px;
$fontSizeLineHeightRegularText: 18px;
$fontSizeLineHeightRegular: 20px;
$fontSizeLineHeight-4: 22px;
$fontSizeLineHeight-3: 28px;
$fontSizeLineHeight-2: 36px;
$fontSizeLineHeight-1: 42px;
$calculatorDropdownColor: #ffffff;

@mixin generics() {
    .sr-disclaimer{
        font-size: $fontSizeFinePrint;
        color: $textColor;
    }

    .sr-text{
        color: $textColor;
        font-size: $fontSizeRegularText;
        font-family: $fontFamily;
    }
}

// We use `is-` for styles affecting the element itself.
// We use `has-` for styles affecting the element's surronding.
@mixin baseline() {
    // Font weights.
    .is-regular {
        @include fontWeight($fontFamily, normal);
    }

    .is-bold {
        @include fontWeight($boldFontFamily, $boldFontWeight);
    }

    // Font sizes.
    .is-small {
        font-size: $fontSizeSmall;
        line-height: $fontSizeLineHeightSmall;
    }

    // Font styles.
    .is-centered {
        text-align: center;
    }

    // Prevent from site like kia where we can't have uppercase characters.
    .is-uppercase {
        text-transform: $isUppercase;
    }

    // Font colors.
    .is-primary {
        color: $textColor;
    }
    .is-alternate {
        color: $titleSecondaryColor;
    }

    .is-success {
        color: $successColor;
    }

    .is-warning {
        color: $warningColor;
    }

    .is-util {
        color: $utilColor;
    }

    .is-price {
        color: $priceColor;
        border-color: $priceColor;
    }

    .is-light {
        color: $lightColor;
    }

    // Backgrounds
    .has-primary-background {
        background-color: $bgPrimaryColor;
    }

    .has-secondary-background {
        background-color: $bgSecondaryColor;
    }

    .has-price-background {
        background-color: $priceColor;
    }

    .has-warning-background {
        background-color: $warningColor;
    }

    .has-success-background {
        background-color: $successColor;
    }

    .has-warning-background {
        background-color: $warningColor;
    }
}


@mixin titles() {
    .sr-title {
        &-1 {
            @include titleFactory($fontSize-1, $fontSizeLineHeight-1);
            @include fontWeight($titleFontFamily, $titleFontWeight);
            @include breakpoint(mobile){
                font-size: 20px;
                line-height: 24px;
            }
        }
        &-2 {
            @include titleFactory($fontSize-2, $fontSizeLineHeight-2);
            @include fontWeight($titleFontFamily, $titleFontWeight);
            @include breakpoint(mobile){
                font-size: 18px;
                line-height: 20px;
            }
        }
    }

    .sr-subtitle {
        &-1 {
            @include titleFactory($fontSize-3, $fontSizeLineHeight-3);
            @include fontWeight($titleFontFamily, $titleFontWeight);
            @include breakpoint(mobile){
                font-size: 16px;
                line-height: 20px;
            }
        }
        &-2 {
            @include titleFactory($fontSize-4, $fontSizeLineHeight-4);
            @include fontWeight($titleFontFamily, $titleFontWeight);
            @include breakpoint(mobile){
                font-size: 14px;
                line-height: 18px;
            }
        }
    }
}


@mixin buttons() {
    .sr-button {
        &-1 {
            @include buttonFactory(
                $type: 'full',
                $color: $btnColorAlpha,
                $background: $bgBtnAlpha,
                $textTransform: $isUppercase
            );
        }
        &-2 {
            @include buttonFactory(
                $type: 'full',
                $color: $btnColorBeta,
                $background: $bgBtnBeta,
                $textTransform: $isUppercase
            );
        }
    }

    .sr-button-outline {
        &-1 {
            @include buttonFactory(
                $type: 'outline',
                $color: $colorBtnOutlineAlpha,
                $background: transparent,
                $textTransform: none
            );

                &:hover:not(.is-disabled) {
                    opacity: 0.65;
                }

                &.is-hover, &.is-hover:hover{
                    opacity: 1;
                    background: $colorBtnOutlineAlpha;
                    color: white;
                    border-color: $colorBtnOutlineAlpha;
                }
        }
        &-2 {
            @include buttonFactory(
                $type: 'outline',
                $color: $colorBtnOutlineBeta,
                $background: transparent,
                $textTransform: none
            );

                &:hover:not(.is-disabled) {
                    opacity: 0.65;
                }

                &.is-hover, &.is-hover:hover{
                    opacity: 1;
                    background: $colorBtnOutlineBeta;
                    color: white;
                    border-color: $colorBtnOutlineBeta;
                }
        }
    }
}

@mixin links() {
    .sr-link{
        color: $linkColor;
        text-decoration: underline;
        border: 0;
        background: 0;
        cursor: pointer;
        padding: 0;
        transition: opacity ease .3s;
        &:hover{
            opacity: .65;
        }
        &:visited{
            color: $linkColor;
        }
        &::before{
            color: $linkColor;
        }
    }
}


/*
    Widgets specific styling.
*/
@mixin checkout(){
    .checkout__content .financing-form{
        input[type="radio"]:checked+label{
            background: $bgSecondaryColor;
        }
    }
}

@mixin sidebarsummary(){
    .sr-sidebarsummary{
        font-family: $fontFamily;
        .sr-title{
            color: #111111;
            margin-bottom: 0;
        }
        .sr-text-regular{
            color: #555555;
            font-size: 12px;
        }
        .sr-text-bold{
             @extend .sr-text-regular;
            @include boldFont($boldFontFamily, $boldFontWeight);
            color: #111111
        }
        .sr-text-thin{
            font-size: 11px;
            color: #aaaaaa;
        }
        .sr-important-price{
            font-size: 18px;
            color: #333333;
        }
        .vehicle{
            &__totalPayment .sr-important-price{
                color: $priceColor;
            }
        }
        .SidebarSummary{
            &__listingAmount{
                color: #333333;
            }
        }
    }
}

@mixin headerBuildBuy(){
    .section-showroom-header__progressBar-stage{
        &.validated{
            .progressBar-stage__active-effect{
                background-color: $priceColor;
                fill: $priceColor;
            }
            .icon{
                color: $utilColor;
            }
        }
        &.selected{
            .progressBar-stage__active-effect{
                background-color: $priceColor;
                fill: $priceColor;
            }
        }
    }
}

@mixin forms(){
    form, .sr-form{
        textarea{
            border-radius: $borderRadiusForm;
            border: $borderSizeForm $borderColorForm;
            @include placeholder-color($labelColor);
            color: $formColor;
        }
        input[type="radio"],
        input[type="checkbox"]{
            &+label{
                &:before{
                    border-color: $labelColor;
                }
                &:after{
                    background-color: $utilColor;
                }
            }
            &:checked+label{
                background-color: transparent;
                &:before{
                    border-color: $utilColor;
                }
                &::after{
                    opacity: 1;
                }
            }
        }
        .field-error input[type="text"]:focus {
            border: 2px solid $errorColor !important;
        }
    }
    .label{
        background: transparent;
        color: inherit;
    }
    .sr-slide{
        .rc-slider-track, .rc-slider-handle{
            background-color: $priceColor;
        }
    }
}

@mixin paymentCalculator(){
    .sr-calculator-tabs{
        background-color: $calculatorTabsColor;
        text-align: center;

        li{
            color: $calculatorTabsTextColor;
            &.current{
                background-color: transparent;
                border-color: $utilColor;
            }
        }
    }
    .sr-paymentCalculator-background{
        background-color: $paymentCalculatorClosedBG;
        color: $paymentCalculatorClosedColor;
    }
    .sr-paymentCalculator-Panelbackground{
        background-color: $paymentCalculatorPanelBG;
    }
    .sr-paymentCalculator__calculatorTerm--background{
        background-color: $paymentCalculatorTermWrapperBG;
        color: $paymentCalculatorTermWrapperColor;
        &.selected{
            background-color: $paymentCalculatorTermSelectedWrapperBG;
            .term{
                background-color: $priceColor;
                color: $paymentCalculatorTermSelectedColor;
            }
            .price{
                span{
                    color: $priceColor;
                }
            }
        }
        .term{
            background-color: $paymentCalculatorTermBG;
            color: $paymentCalculatorTermColor;
        }
        .price{
            span{
                color: #ffffff;
            }
        }
    }
    .sr-paymentCalculator-EmphasisColor{
        color: #ffffff;
    }
    .sr-paymentCalculator-dropdown .Select-control, .sr-paymentCalculator-dropdown .is-focused .Select-control, .sr-paymentCalculator-dropdown .Select-option{
        background-color: #363a4d;
        border: 0;
        color: $calculatorDropdownColor !important;
        &.is-selected{
            background-color: #363a4d;
            color: $calculatorDropdownColor !important;
        }
    }
    .sr-paymentCalculator-dropdown .Select-value-label, .sr-paymentCalculator-dropdown .Select-input>input{
        color: $calculatorDropdownColor !important;
    }
    .sr-paymentCalculator-Panelbackground .rc-slider-track{
        background-color: $priceColor;
    }
    .sr-paymentCalculator-Panelbackground .rc-slider-handle{
        border-color: $priceColor;
    }
}

@mixin login(){
    .sr-topbar-login{
        background-color: $bgTopbarLogin;
        color: $colorTopbarLogin;
        .btn-login{
             icon, .label{
                color: $colorTopbarLogin;
            }
        }
        .message-banner{
            .message-content{
                font-size: 16px;
            }
            .icon-close{
                border: 1px solid;
                border-radius: 100%;
                padding: 3px;
                width: 35px;
                height: 35px;
                display: inline-block;
                &:after{
                    content: "\01F5D9";
                    font-size: 21px;
                    position: relative;
                    left: 3px;
                    top: -1px;

                }
            }
            .icon-round-check{
                border: 1px solid;
                border-radius: 100%;
                padding: 3px;
                width: 35px;
                height: 35px;
                display: inline-block;
                &:after{
                    content: "\2713";
                    position: relative;
                    left: 3px;
                }
            }
        }


    }
}

@mixin garage(){
    .sr-garage{
        background-color: $garageBackground;
        color: $garageTextColor;
    }
    .widget-garage{
        .slide-bloc{
            background-color: $garageBackground;
            transition: all 0.3s ease-in;
        }
        .pendingVehicle{
            .slide-bloc{
                background-color: darken($garageBackground, 10%);
            }
        }
        .vehicle-wrapper-image{
            .payment-car-status{
                background-color: $bgBtnAlpha;
                color: $btnColorAlpha;
                @include breakpoint(mobile){
                    background-color: transparent;
                    color: $textColor;
                }
                &.new-vehicle{
                    background-color: darken($bgBtnAlpha, 5%);
                    @include breakpoint(mobile){
                        background-color: transparent;
                        color: $textColor;
                    }
                }
            }
        }
    }
}

@mixin protections(){
    .sr-protection{
        // This is just a white title ... could be sr-subtitle-1 or sr-sutbtitle-2 ??
        &-category-title{
            @include boldFont($boldFontFamily, $boldFontWeight);
            font-size: 22px;
            color: #fff;
        }
        &-category-descrition{
            font-size: 14px;
            color: #cccccc;
        }
        &-package-title{
            font-weight: 300;
            font-size: 20px;
            color: $textColor;
        }
        &-package-descrition{
            font-size: 12px;
            color: $textColor;
        }
    }
}

@mixin progressBar(){
    .sr-progressBar{
        &-bg-title{
            background-color: $progressBarTitleBG;
            color: $progressBarTitleColor;
            &.is-open{
                background-color: $progressBarTitleCloseBG;
                color: $progressBarTitleCloseColor;
            }
        }
        &-closeButton{
            background-color: $progressBarCloseButtonBG;
            color: $progressBarCloseButtonColor;
        }
        .TransactionSummaryUnit--single{
            &:nth-child(1){
                border-bottom-color: lighten($progressBarTitleBG, 50%);
            }
            &:nth-child(2){
                border-bottom-color: lighten($progressBarTitleBG, 40%);
            }
            &:nth-child(3){
                border-bottom-color: lighten($progressBarTitleBG, 30%);
            }
            &:nth-child(4){
                border-bottom-color: lighten($progressBarTitleBG, 20%);
            }
            &:nth-child(5){
                border-bottom-color: lighten($progressBarTitleBG, 0);
            }
        }
    }
}
