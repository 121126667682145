@mixin fontWeight($family, $weight) {
  font-family: $family;
  font-weight: $weight;
}

@mixin titleFactory($size, $lineHeight) {
  color: $titleColor;
  font-size: $size;
  line-height: $lineHeight;
  text-align: left;
}

// TODO: can we access borderRadiusButton / textTransform theme variables directly?
// TODO: create new theme variables: padding, textTransform
// TODO: Find a way to deal with icons (before / after text...)
@mixin buttonFactory(
  $type,
  $color, 
  $background,
  $textTransform
) {

  // Common style for full and outline buttons.
  color: $color;
  background: $background;
  font-family: $fontFamily;
  font-size: 12px;
  text-transform: $textTransform;
  padding: $paddingButtonPrimary;
  min-width: 180px;
  border-radius: $borderRadiusButton;
  opacity: 1;
  line-height: 1em;
  outline: none;
  cursor: pointer;

  // Utility classes.
  &.is-fluid {
    width: 100%;
  }

  &.is-darker {
    background: darken($background, 10% );
    // color: darken($color, 10%); Should we darken the color?
  }

  &.is-small {
    padding: $paddingButtonSecondary;
    text-transform: none;
    font-size: 12px;
    line-height: 1em;
    min-width: 0;
    @include fontWeight($fontFamily, normal);
  }

  // Icons.
  & icon:first-child:not(:last-child) {
    margin-right: 10px;
  }

  & icon:last-child:not(:first-child) {
    margin-left: 10px;
  }

  // TODO: Ask Eddy to see if this case could happen?
  & icon:last-child:first-child {
    margin-left: 10px;
    margin-right: 10px;
  }

  @include breakpoint(mobile){
    min-width: 0;
  }

  &:focus {
    box-shadow: 0 0 4px 2px rgba(0,162,253,0.2), 0 1px 4px 0 rgba(0,0,0,0.6);
    outline: none;
  }

  &::-moz-focus-inner {
    border: 0;
  }
  
  @if $type == 'full' {
    border: none;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.3);
    transition: opacity ease .3s;
    @include fontWeight($boldFontFamily, $boldFontWeight);

    &:hover {
      opacity: 0.65
    }

    &.is-disabled {
      background: #D1D1D1;
      opacity: 0.65;
      cursor: not-allowed;
    }
  }
  @else if $type == 'outline' {
    border: 1px solid;
    box-shadow: none;
    padding: $paddingButtonSecondary;
    transition: background-color ease .3s, color ease .3s;

    &.is-disabled {
      opacity: 0.35;
      cursor: not-allowed;
      color: $colorBtnOutlineAlpha;
    }
  }
}


@mixin breakpoint($point) {

  $xs-mobile: "(max-width: 360px)";
  $s-mobile: "(max-width: 375px)";
  $mobile: "(max-width: 767px)";
  $tablet: "(min-width: 768px) and (max-width: 1024px)";
  $tabletLandscape: "(min-width: 768px) and (max-width: 1024px) and (orientation:landscape)";
  $tabletPortrait: "(min-width: 768px) and (max-width: 1024px) and (orientation:portrait)";
  $mobileTablet: "(max-width: 1024px)";
  $mobileTabletLandscape: "(max-width: 1024px) and (orientation:landscape)";
  $mobileTabletPortrait: "(max-width: 1024px) and (orientation:portrait)";
  $laptop: "(min-width: 1025px) and (max-width: 1440px)";
  $desktop: "(min-width: 1025px)";
  $calculator: "(max-width: 1260px)";
  $largeDesktop: "(min-width: 1440px)";

  @if $point == tablet {
    @media #{$tablet} { @content; }
  }
  @else if $point == tabletPortrait {
    @media #{$tabletPortrait} { @content; }
  }
  @else if $point == tabletLandscape {
    @media #{$tabletLandscape} { @content; }
  }
  @else if $point == xs-mobile {
    @media #{$xs-mobile} { @content; }
  }
  @else if $point == s-mobile {
    @media #{$s-mobile} { @content; }
  }
  @else if $point == mobile {
    @media #{$mobile} { @content; }
  }
  @else if $point == mobileTablet {
    @media #{$mobileTablet} { @content; }
  }
  @else if $point == mobileTabletLandscape {
    @media #{$mobileTabletLandscape} { @content; }
  }
  @else if $point == mobileTabletPortrait {
    @media #{$mobileTabletPortrait} { @content; }
  }
  @else if $point == laptop {
    @media #{$laptop} { @content; }
  }
  @else if $point == desktop {
    @media #{$desktop} { @content; }
  }
  @else if $point == smallDesktop {
    @media #{$calculator} { @content; }
  }

}

@mixin clearfix() {
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}

.clearfix{
    @include clearfix();
}
// Border radius shorthand
@mixin border-radius($radius) {
  border-radius: $radius;
}

@mixin box-shadow($top, $left, $blur, $size, $color, $inset: false) {
  @if $inset {
    box-shadow: inset $top $left $blur $size $color;
  } @else {
    box-shadow: $top $left $blur $size $color;
  }
}

@mixin gradient($start-color, $end-color, $orientation) {
  background: $start-color;
  @if $orientation == 'vertical' {
    background: -webkit-linear-gradient(top, $start-color, $end-color);
    background: linear-gradient(to bottom, $start-color, $end-color);
  } @else if $orientation == 'horizontal' {
    background: -webkit-linear-gradient(left, $start-color, $end-color);
    background: linear-gradient(to right, $start-color, $end-color);
  } @else {
    background: -webkit-radial-gradient(center, ellipse cover, $start-color, $end-color);
    background: radial-gradient(ellipse at center, $start-color, $end-color);
  }
}

@mixin placeholder-color($color) {
    ::-webkit-input-placeholder {
        color: $color;
    }
    :-moz-placeholder {
        color: $color;
        opacity: 1;
    }
    ::-moz-placeholder {
        color: $color;
        opacity: 1;
    }
    :-ms-input-placeholder {
        color: $color;
    }
}

@function calculateRem($size) {
    $remSize: $size / 16px;
    @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
}


@mixin boldFont($boldFontFamily, $boldFontWeight){
    font-family: $boldFontFamily;
    font-weight: $boldFontWeight;
}
@mixin thinFont($thinFontFamily, $thinFontWeight){
    font-family: $thinFontFamily;
    font-weight: $thinFontWeight;
}
