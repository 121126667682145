@font-face {
	font-family: 'showroom';
	src:
		/* BEWARE:
			Here the url is harcoded to work when built
			DO NOT MODIFY !!! */
		url('../assets/fonts/showroom/showroom.woff2?t6ht2f') format('woff2'),
		url('../assets/fonts/showroom/showroom.ttf?t6ht2f') format('truetype'),
		url('../assets/fonts/showroom/showroom.woff?t6ht2f') format('woff'),
		url('../assets/fonts/showroom/showroom.svg?t6ht2f#showroom') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'crm-desking';
	src:
		/* BEWARE:
			Here the url is harcoded to work when built
			DO NOT MODIFY !!! */
		url('../assets/fonts/crm-desking/crm-desking.woff2?ozh8nj') format('woff2'),
		url('../assets/fonts/crm-desking/crm-desking.ttf?ozh8nj') format('truetype'),
		url('../assets/fonts/crm-desking/crm-desking.woff?ozh8nj') format('woff'),
		url('../assets/fonts/crm-desking/crm-desking.svg?ozh8nj#crm-desking') format('svg');
	font-weight: normal;
	font-style: normal;
}

.widget-sr{
	[class^="icon-"], [class*=" icon-"] {
		/* use !important to prevent issues with browser extensions that change fonts */
		font-family: 'showroom' !important;
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;

		/* Better Font Rendering =========== */
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	/** CBB ICONS **/
	.icon-cbb-icons-alert-message-en:before{
		content: "\e9e5";
	}
	.icon-cbb-icons-alert-message-fr:before{
		content: "\e9ed";
	}
	.icon-cbb-icons-checked-option:before{
		content: "\e9f5";
	}
	.icon-cbb-icons-contact:before{
		content: "\e9f6";
	}
	.icon-cbb-icons-inspection:before{
		content: "\e9fe";
	}
	.icon-cbb-icons-vehicle-condition:before{
		content: "\ea02";
	}
	.icon-cbb-icons-vehicle-information:before{
		content: "\ea05";
	}
	.icon-cbb-icons-vehicle-options:before{
		content: "\ea0b";
	}
	.icon-icon-edit:before{
		content: "\ea0e";
	}
	/** END CBB ICONS **/
	
	.icon-check-mark:before {
		content: "\e9e2";
	}

	.icon-check-mark:before {
		content: "\e9e2";
	}
	.icon-camera-off:before {
		content: "\e9e0";
	}
	.icon-Thumb-up:before {
		content: "\e9dc";
	}
	.icon-SetBudget:before {
		content: "\e9dd";
	}
	.icon-Tax:before {
		content: "\e9df";
	}
	.icon-Cab-CC:before {
		content: "\e9db";
	}
	.icon-Crochet:before {
		content: "\e90b";
	}
	.icon-map:before {
		content: "\e9da";
	}
	.icon-arrow-circle:before {
		content: "\e9d9";
	}
	.icon-non-photo:before {
		content: "\e9d8";
	}
	.icon-smiley-happy:before {
		content: "\e9d4";
	}
	.icon-smiley-mad:before {
		content: "\e9d5";
	}
	.icon-electric-vehicle:before {
		content: "\e9d3";
	}
	.icon-Box-SB:before {
		content: "\e9d2";
	}
	.icon-Box-MB:before {
		content: "\e9de";
	}
	.icon-Box-LB:before {
		content: "\e9ea";
	}
	.icon-Cab-XC:before {
		content: "\e9f7";
	}
	.icon-Cab-RC:before {
		content: "\e9f8";
	}
	.icon-promo2:before {
		content: "\e9d0";
	}
	.icon-minus:before {
		content: "\e900";
	}
	.icon-add:before {
		content: "\e901";
	}
	.icon-Alert-Prix-Off:before {
		content: "\e902";
	}
	.icon-Alert-Prix-On:before {
		content: "\e903";
	}
	.icon-Arrow-Left:before {
		content: "\e904";
	}
	.icon-Arrow-Menu-Top:before {
		content: "\e905";
	}
	.icon-Arrow-Popup-Left:before {
		content: "\e906";
	}
	.icon-Arrow-Right:before {
		content: "\e907";
	}
	.icon-Big-Arrow-Left:before {
		content: "\e908";
	}
	.icon-radio-button-off:before {
		content: "\e909";
	}
	.icon-Big-Arrow-Right:before {
		content: "\e90a";
	}
	.icon-Coeur:before {
		content: "\e90c";
	}
	.icon-Front-Wheel-Drive:before {
		content: "\e90d";
	}
	.icon-Fleche-Ou-Popup:before {
		content: "\e90e";
	}
	.icon-Gaz:before {
		content: "\e90f";
	}
	.icon-radio-button-on:before {
		content: "\e910";
	}
	.icon-Gift:before {
		content: "\e911";
	}
	.icon-Mon-Garage:before {
		content: "\e912";
	}
	.icon-engine:before {
		content: "\e913";
	}
	.icon-Rouage-Config:before {
		content: "\e914";
	}
	.icon-Sieges:before {
		content: "\e915";
	}
	.icon-Vitesse:before {
		content: "\e916";
	}
	.icon-Warning:before {
		content: "\e917";
	}
	.icon-legal:before {
		content: "\e9e1";
	}
	.icon-X-rouge:before {
		content: "\e918";
	}
	.icon-login:before {
		content: "\e919";
	}
	.icon-A_C-Automatique:before {
		content: "\e91a";
	}
	.icon-a_c:before {
		content: "\e91b";
	}
	.icon-Alarm-System:before {
		content: "\e91c";
	}
	.icon-AppleAirPlay:before {
		content: "\e91d";
	}
	.icon-Bi-Zone:before {
		content: "\e91e";
	}
	.icon-Blind-Spot-Assist:before {
		content: "\e91f";
	}
	.icon-Blind-Spot-Detection:before {
		content: "\e920";
	}
	.icon-Bluetooth:before {
		content: "\e921";
	}
	.icon-Camera-Recul:before {
		content: "\e922";
	}
	.icon-V:before {
		content: "\e923";
	}
	.icon-Seats-Heated-Rear:before {
		content: "\e924";
	}
	.icon-Seats-Memory-Driver-passenger:before {
		content: "\e925";
	}
	.icon-Seats-Memory-Driver:before {
		content: "\e926";
	}
	.icon-Detection-Objets-Mouvement:before {
		content: "\e927";
	}
	.icon-Engine:before {
		content: "\e928";
	}
	.icon-Foward-Collision:before {
		content: "\e929";
	}
	.icon-Freins-ABS:before {
		content: "\e92a";
	}
	.icon-Lane-Departure-Assist:before {
		content: "\e92b";
	}
	.icon-Lane-Departure-Warning:before {
		content: "\e92c";
	}
	.icon-Microsoft:before {
		content: "\e92d";
	}
	.icon-Parking-Assist:before {
		content: "\e92e";
	}
	.icon-Power-Moonroof:before {
		content: "\e92f";
	}
	.icon-Power-Tailgate:before {
		content: "\e930";
	}
	.icon-Radio-HD:before {
		content: "\e931";
	}
	.icon-Radio-LCD:before {
		content: "\e932";
	}
	.icon-Radio-Satellite:before {
		content: "\e933";
	}
	.icon-Rear-Cross-Traffic-Alert:before {
		content: "\e934";
	}
	.icon-Remote-Start:before {
		content: "\e935";
	}
	.icon-Sensor-Front:before {
		content: "\e936";
	}
	.icon-Stability-Control:before {
		content: "\e937";
	}
	.icon-Start-Stop:before {
		content: "\e938";
	}
	.icon-Systeme-navigation:before {
		content: "\e939";
	}
	.icon-Temperature-Auto:before {
		content: "\e93a";
	}
	.icon-Traction-Control:before {
		content: "\e93b";
	}
	.icon-Phone:before {
		content: "\e93c";
	}
	.icon-Tri-Zone:before {
		content: "\e93d";
	}
	.icon-Voice-Command:before {
		content: "\e93e";
	}
	.icon-Voice-Recpgnition:before {
		content: "\e93f";
	}
	.icon-x-config:before {
		content: "\e940";
	}
	.icon-Accoustic-Windshield:before {
		content: "\e941";
	}
	.icon-Driver-Knees-Airbags:before {
		content: "\e942";
	}
	.icon-Dual-Front-Airbags:before {
		content: "\e943";
	}
	.icon-Idle-Stop:before {
		content: "\e944";
	}
	.icon-Leather-Wrapped-Shift-Knob:before {
		content: "\e945";
	}
	.icon-Side-Curtain-Airbags:before {
		content: "\e946";
	}
	.icon-Side-Impact-Airbags:before {
		content: "\e947";
	}
	.icon-Steering-Control-Mount:before {
		content: "\e948";
	}
	.icon-Steering-Heated:before {
		content: "\e949";
	}
	.icon-Steering-Leather-Wrap:before {
		content: "\e94a";
	}
	.icon-Steering-Telescopic-Electric:before {
		content: "\e94b";
	}
	.icon-Steering-Telescopic:before {
		content: "\e94c";
	}
	.icon-Steering-Tilt-Electric:before {
		content: "\e94d";
	}
	.icon-Steering-Tilt:before {
		content: "\e94e";
	}
	.icon-Trailer-Hitch:before {
		content: "\e94f";
	}
	.icon-Walk-Away-Door-Locks:before {
		content: "\e950";
	}
	.icon-Wifi:before {
		content: "\e951";
	}
	.icon-Wireless-Charging:before {
		content: "\e952";
	}
	.icon-DVD:before {
		content: "\e953";
	}
	.icon-Check-Box-Off:before {
		content: "\e954";
	}
	.icon-Check-Box-On:before {
		content: "\e955";
	}
	.icon-pencil:before {
		content: "\e956";
	}
	.icon-AndroidCar:before {
		content: "\e957";
	}
	.icon-all-car:before {
		content: "\e958";
	}
	.icon-hatchback:before {
		content: "\e959";
	}
	.icon-sedan:before {
		content: "\e95a";
	}
	.icon-crossover:before {
		content: "\e95b";
	}
	.icon-commercial:before {
		content: "\e95c";
	}
	.icon-truck:before {
		content: "\e95d";
	}
	.icon-suv:before {
		content: "\e95e";
	}
	.icon-coupe:before {
		content: "\e95f";
	}
	.icon-electric:before {
		content: "\e960";
	}
	.icon-hybrid:before {
		content: "\e961";
	}
	.icon-convertible:before {
		content: "\e962";
	}
	.icon-Adaptative-HighBeam:before {
		content: "\e963";
	}
	.icon-Bright-Exhaust-Tip:before {
		content: "\e964";
	}
	.icon-Cruze-Control-Adaptative:before {
		content: "\e965";
	}
	.icon-EBD:before {
		content: "\e966";
	}
	.icon-Emergency-Calls:before {
		content: "\e967";
	}
	.icon-Garage-Opener:before {
		content: "\e968";
	}
	.icon-Hill-Descent-Control:before {
		content: "\e969";
	}
	.icon-Lane-Departure-Assist2:before {
		content: "\e96a";
	}
	.icon-Lane-Departure-Warning2:before {
		content: "\e96b";
	}
	.icon-Led-Adaptative:before {
		content: "\e96c";
	}
	.icon-Led-Auto-Off:before {
		content: "\e96d";
	}
	.icon-Led-Auto-On:before {
		content: "\e96e";
	}
	.icon-Led:before {
		content: "\e96f";
	}
	.icon-Panoramic-Glass-Roof:before {
		content: "\e970";
	}
	.icon-Power-Doors-Lock:before {
		content: "\e971";
	}
	.icon-Power-Sliding-Doors:before {
		content: "\e972";
	}
	.icon-Power-Windows:before {
		content: "\e973";
	}
	.icon-Privacy-Windows:before {
		content: "\e974";
	}
	.icon-Proximity-Key:before {
		content: "\e975";
	}
	.icon-Remote-Entry:before {
		content: "\e976";
	}
	.icon-Seats-Heated-Front:before {
		content: "\e977";
	}
	.icon-Seats-Power-Drive-Passenger:before {
		content: "\e978";
	}
	.icon-Seats-Power-Drive:before {
		content: "\e979";
	}
	.icon-Smart-City-Breaking:before {
		content: "\e97a";
	}
	.icon-Steering-Paddle-Shifter:before {
		content: "\e97b";
	}
	.icon-Tires-Presure:before {
		content: "\e97c";
	}
	.icon-Whipers-Heated:before {
		content: "\e97d";
	}
	.icon-Whipers-Rain-Sensitive:before {
		content: "\e97e";
	}
	.icon-Whipers-Speed-Sensitive:before {
		content: "\e97f";
	}
	.icon-Mirrors-Body-Color:before {
		content: "\e980";
	}
	.icon-Mirrors-Chrome:before {
		content: "\e981";
	}
	.icon-Mirrors-Electric-Folding:before {
		content: "\e982";
	}
	.icon-Mirrors-Folding:before {
		content: "\e983";
	}
	.icon-Mirrors-Heated:before {
		content: "\e984";
	}
	.icon-Mirrors-Integrated-Turn-Signals:before {
		content: "\e985";
	}
	.icon-Mirrors-Power:before {
		content: "\e986";
	}
	.icon-Seats-Leather:before {
		content: "\e987";
	}
	.icon-Seats-Leatherette:before {
		content: "\e988";
	}
	.icon-Photos:before {
		content: "\e989";
	}
	.icon-plus:before {
		content: "\e98a";
	}
	.icon-download:before {
		content: "\e98b";
	}
	.icon-calculatrice:before {
		content: "\e98c";
	}
	.icon-angle-double-right:before {
		content: "\e98d";
	}
	.icon-angle-double-left:before {
		content: "\e98e";
	}
	.icon-stopwatch:before {
		content: "\e98f";
	}
	.icon-Filter-Mobile:before {
		content: "\e990";
	}
	.icon-keyboard_arrow_right:before {
		content: "\e991";
	}
	.icon-keyboard-arrow-left:before {
		content: "\e992";
	}
	.icon-chevron-thin-down:before {
		content: "\e993";
	}
	.icon-radio-button-off2:before {
		content: "\e994";
	}
	.icon-radio-button-on2:before {
		content: "\e995";
	}
	.icon-calculator:before {
		content: "\e996";
	}
	.icon-do-not-disturb:before {
		content: "\e997";
	}
	.icon-search:before {
		content: "\e998";
	}
	.icon-boni:before {
		content: "\e999";
	}
	.icon-winter-tire:before {
		content: "\e99a";
	}
	.icon-drive-off:before {
		content: "\e99b";
	}
	.icon-drive-on:before {
		content: "\e99c";
	}
	.icon-morning:before {
		content: "\e99d";
	}
	.icon-day:before {
		content: "\e99e";
	}
	.icon-night:before {
		content: "\e99f";
	}
	.icon-tag:before {
		content: "\e9a0";
	}
	.icon-refresh:before {
		content: "\e9a1";
	}
	.icon-km:before {
		content: "\e9a2";
	}
	.icon-lock:before {
		content: "\e9a3";
	}
	.icon-keyboard_arrow_down:before {
		content: "\e9a4";
	}
	.icon-keyboard_arrow_up:before {
		content: "\e9a5";
	}
	.icon-Checkout-step1:before {
		content: "\e9a6";
	}
	.icon-Checkout-step2:before {
		content: "\e9a7";
	}
	.icon-Checkout-step3:before {
		content: "\e9a8";
	}
	.icon-Checkout-step5:before {
		content: "\e9a9";
	}
	.icon-Checkout-step4:before {
		content: "\e9aa";
	}
	.icon-Checkout-car:before {
		content: "\e9ab";
	}
	.icon-Wheels:before {
		content: "\e9ac";
	}
	.icon-wagon-station:before {
		content: "\e9ad";
	}
	.icon-carproof:before {
		content: "\e9ae";
	}
	.icon-shopping-cart:before {
		content: "\e9af";
	}
	.icon-odometer:before {
		content: "\e9b0";
	}
	.icon-alert:before {
		content: "\e9b1";
	}
	.icon-garage:before {
		content: "\e9b2";
	}
	.icon-powertrain:before {
		content: "\e9b3";
	}
	.icon-transmission:before {
		content: "\e9b4";
	}
	.icon-eye:before {
		content: "\e9b5";
	}
	.icon-fuel:before {
		content: "\e9b6";
	}
	.icon-passenger:before {
		content: "\e9b7";
	}
	.icon-brochure:before {
		content: "\e9b8";
	}
	.icon-calendar:before {
		content: "\e9b9";
	}
	.icon-road:before {
		content: "\e9ba";
	}
	.icon-Arrow-Left-2:before {
		content: "\e9bb";
	}
	.icon-promo:before {
		content: "\e9bc";
	}
	.icon-play-button:before {
		content: "\e9bd";
	}
	.icon-deposit:before {
		content: "\e9be";
	}
	.icon-financing:before {
		content: "\e9bf";
	}
	.icon-protection:before {
		content: "\e9c0";
	}
	.icon-review:before {
		content: "\e9c1";
	}
	.icon-sales:before {
		content: "\e9c2";
	}
	.icon-dots:before {
		content: "\e9c3";
	}
	.icon-empty-star:before {
		content: "\e9c4";
	}
	.icon-trash-can:before {
		content: "\e9c5";
	}
	.icon-certified-badge:before {
		content: "\e9c6";
	}
	.icon-facebook:before {
		content: "\e9c7";
	}
	.icon-eye2:before {
		content: "\e9c8";
	}
	.icon-exit:before {
		content: "\e9c9";
	}
	.icon-image:before {
		content: "\e9ca";
	}
	.icon-star:before {
		content: "\e9cb";
	}
	.icon-camera:before {
		content: "\e9cc";
	}
	.icon-bodystyle:before {
		content: "\e9cd";
	}
	.icon-doors:before {
		content: "\e9ce";
	}
	.icon-info:before {
		content: "\e9cf";
	}
	.icon-tradein:before {
		content: "\e9d1";
	}
	.icon-expand:before {
		content: "\e9d6";
	}
	.icon-gallery:before {
		content: "\e9d7";
	}

	[class^="icon-crm-desking-"], [class*=" icon-crm-desking-"] {
		/* use !important to prevent issues with browser extensions that change fonts */
		font-family: 'crm-desking' !important;
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;

		/* Better Font Rendering =========== */
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.icon-crm-desking-arrow_down:before {
		content: "\e900";
	}
	.icon-crm-desking-arrow_top:before {
		content: "\e901";
	}
	.icon-crm-desking-arrow:before {
		content: "\e902";
	}
	.icon-crm-desking-arrow_right:before {
		content: "\e903";
	}
	.icon-crm-desking-arrow_45:before {
		content: "\e904";
	}
	.icon-crm-desking-arrow-outline-down:before {
		content: "\e905";
	}
	.icon-crm-desking-arrow-outline-right:before {
		content: "\e906";
	}
	.icon-crm-desking-add:before {
		content: "\e907";
	}
	.icon-crm-desking-check:before {
		content: "\e908";
	}
	.icon-crm-desking-deleteX-Copy-3:before {
		content: "\e909";
	}
	.icon-crm-desking-more-red:before {
		content: "\e90a";
	}
	.icon-crm-desking-doc2:before {
		content: "\e90b";
	}
	.icon-crm-desking-phone:before {
		content: "\e90c";
	}
	.icon-crm-desking-email:before {
		content: "\e90d";
	}
	.icon-crm-desking-sms:before {
		content: "\e90e";
	}
	.icon-crm-desking-notification:before {
		content: "\e90f";
	}
	.icon-crm-desking-print-red:before {
		content: "\e910";
	}
	.icon-crm-desking-car:before {
		content: "\e911";
	}
	.icon-crm-desking-close:before {
		content: "\e912";
	}
	.icon-crm-desking-financing:before {
		content: "\e913";
	}
	.icon-crm-desking-garage:before {
		content: "\e914";
	}
	.icon-crm-desking-Lock:before {
		content: "\e915";
	}
	.icon-crm-desking-pin:before {
		content: "\e916";
	}
	.icon-crm-desking-promo:before {
		content: "\e917";
	}
	.icon-crm-desking-push-notification:before {
		content: "\e918";
	}
	.icon-crm-desking-secure:before {
		content: "\e919";
	}
	.icon-crm-desking-trade-in:before {
		content: "\e91a";
	}
	.icon-crm-desking-color:before {
		content: "\e91b";
	}
	.icon-crm-desking-interor:before {
		content: "\e91c";
	}
	.icon-crm-desking-km:before {
		content: "\e91d";
	}
	.icon-crm-desking-service:before {
		content: "\e91e";
	}
	.icon-crm-desking-info:before {
		content: "\e91f";
	}
	.icon-crm-desking-print-copy-2:before {
		content: "\e920";
	}
	.icon-crm-desking-warning:before {
		content: "\e921";
	}
	.icon-crm-desking-notes .path1:before {
		content: "\e922";
		opacity: 0.4;
	}
	.icon-crm-desking-notes .path2:before {
		content: "\e923";
		margin-left: -1em;
		opacity: 0.4;
	}
	.icon-crm-desking-notes .path3:before {
		content: "\e924";
		margin-left: -1em;
		opacity: 0.4;
	}
	.icon-crm-desking-notes .path4:before {
		content: "\e925";
		margin-left: -1em;
		opacity: 0.4;
	}
	.icon-crm-desking-notes .path5:before {
		content: "\e926";
		margin-left: -1em;
		opacity: 0.4;
	}
	.icon-crm-desking-notes .path6:before {
		content: "\e927";
		margin-left: -1em;
		opacity: 0.4;
	}
	.icon-crm-desking-notes .path7:before {
		content: "\e928";
		margin-left: -1em;
		opacity: 0.4;
	}
	.icon-crm-desking-notes .path8:before {
		content: "\e929";
		margin-left: -1em;
		opacity: 0.4;
	}
	.icon-crm-desking-notes .path9:before {
		content: "\e92a";
		margin-left: -1em;
		opacity: 0.4;
	}
	.icon-crm-desking-photos:before {
		content: "\e92b";
	}
	.icon-crm-desking-rapport:before {
		content: "\e92c";
	}
	.icon-Apple-CarPlay:before {
		content: "\e9e4";
	}
	.icon-delivery-preference:before {
		content: "\e9e6";
	}
	.icon-tradein-icon-options:before {
		content: "\e9e7";
	}
}
