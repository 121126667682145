html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
}

// Made for overwrite webauto's 'styles
:lang(en) .widget-sr{
    .section-showroom-header__model-listing-item .info-vehicle__make{
        padding-right: 5px;
    }
}

.widget-sr {
    * {
        box-sizing: border-box;
    }
    ol, ul {
        list-style: none;
        padding-left: 0;
        margin: 0;
    }
    .button{
        padding: 0;
        margin: 0;
    }
    #button-login,#button-register{
        cursor: pointer;
    }
    #button-login .label,#button-register .label{
        padding: 0 .5rem;
    }
    .login-content .button{
        font-size: 13px;
        min-height: unset;
        font-weight: unset;
    }
    .vehicle-form {
        h1,h2,h3,.sr-subtitle-1{
            text-align: center !important;
        }
        .vehicle-form-title{
            text-align: center !important;
        }
        .vehicle-form-body{
            .trade-in-form{
                margin: 0 auto;
            }
        }
    }
    .TransactionAction {
        &--details {
            max-height: calc(100vh - 150px) !important;
            overflow-y: auto !important;
        }
        &--single{
            &.is-validate{
                button img{
                    filter: none !important;
                }
            }
        }
    }
    .listing-used-header .sr-title-1{
        width: 100%;
        max-width: 1200px;
        padding: 0 15px;
        margin: 0 auto 30px;
    }
    .ReactModal__Overlay.ReactModal__Overlay--after-open{
        min-height: 100vh;
    }
    .ReactModal__Content.ReactModal__Content--after-open.widget-profile-popupElement {
        position: relative;
    }
    .style-color__row-top .style-color__label.sr-subtitle-2 div{
        display: inline;
    }
    .vehicle-overview__optionalButton{
        margin-top: 0;
    }
    .vdp-used-summary .vehicle-overview__optionalButton{
        display: none;
    }
    .section-checkout-header__progressBar-stage .progressBar-stage__content{
        z-index: unset;
    }
    .widget-register-popupElement #receivePromotionalEmail label{
        text-transform: none;
    }
    .TradeInTile--content-estimate img{
        max-width: 140px;
    }
    .TradeInTile--content-official img{
        max-width: 64px;
    }
    .showroom-vdp-used-options .showroom-vdp-used-options-list .showroom-vdp-used-options-item{
        list-style: inside;
    }
    .delivery-options-info.sr-text{
        line-height: 20px;
    }
    /** Fix Opt-in checkbox on Sign Up Form **/
    .SignUpForm input[type=checkbox]+label:before,
    .SignUpForm input[type=checkbox]:checked+label:before{
        top: 7px !important;
        height: 12px !important;
        width: 12px !important;
        left: 0px !important;
        border-radius: 0px !important;
    }
    .SignUpForm input[type=checkbox]:checked+label:before{
        border-width: 2px;
    }
    .SignUpForm input[type=checkbox]+label:after,
    .SignUpForm input[type=checkbox]:checked+label:after{
        height: 8px !important;
        width: 8px !important;
        top: 3px !important;
        left: 3px !important;
        transform: rotate(0deg)!important;
        border-radius: 0px !important;
    }
    /** fix trade-in form checkbox **/
    .officialTradeIn__section.carCondition{
        .checkbox-button-cta-wrapper{
            [type=checkbox]:checked+label:before,
            [type=checkbox]:not(:checked)+label:before{
                top: 5px;
            }
            [type=checkbox]:checked+label:after,
            [type=checkbox]:not(:checked)+label:after{
                top: 0px;
                left: 4px;
                width: 10px;
                height: 10px;
                border-radius: 2px;
                transform: none;
            }
        }
    }
    /** Align VLP tiles disclaimer **/
    .vehicle-note .VehicleDisclaimersContent,
    .vehicle-note__wrapper .VehicleDisclaimersContent {
        text-align: left;
    }
    /** fix listing-tile-powertrains 3 icons width **/
    .listing-tile .listing-tile-drivePowerTrains .listing-tile-km,
    .listing-tile .listing-tile-drivePowerTrains .listing-tile-transmission,
    .listing-tile .listing-tile-drivePowerTrains .listing-tile-drivetrain{
        width: 33%;
    }
    /** fix demo tags **/
    .listing-new-tile .listing-new-tile-image .new-car-image-wrapper .demo-tag,
    .listing-new-tile .listing-new-tile-image .new-car-image-wrapper .demo-tag.with-reserve-tag{
        top: 10px;
        left: 10px;
        height: 27px;
    }
}

@include breakpoint(mobileTablet){
    .widget-sr {
        .vehicleBanner--image{
            height: unset;
        }
    }
}
@include breakpoint(tablet) {
    .widget-sr {
        .vehicleBanner--image{
            height: unset;
            top: 0;
            left: 0;
            transform: unset;
        }
        .listing-used-car-no-result{
            margin: 14px 20px;
        }
        .vehicle-overview__image{
            margin: 0 0 30px 0;
        }
        .vehicle-overview__optionalButton{
            margin-top: 10px;
        }
        .vehicle-extra-cta--content{
            padding: 60px 20px 20px;
        }
    }
}
@include breakpoint(tabletPortrait) {
    .widget-sr {
        .sr-calculator-tabs li{
            margin: 0 10px;
        }
        .vdp-used-banner--details{
            width: 50%;
        }
        .vdp-used-banner--image{
            width: 50%;
        }
        .vehicleBanner--image{
            height: unset;
            top: 0;
            left: 0;
            transform: unset;
        }
    }
}

@include breakpoint(desktop){
    .widget-sr {
        .purchase-methods-tab{
            &__tabs{
                padding: 0 30%;
            }
        }
        .vehicle-overview__summary .vehicle-overview{
            max-width: 500px;
        }
        .vehicle-extra-cta--content{
            padding: 60px 20px 20px;
        }
    }
}